.font-lg {
  font-size: 18px !important;
}
.no-margin {
  margin:0 !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.no-border {
  border: none !important;
}
.no-box-shadow {
  box-shadow: none !important;
}
.no-padding {
  padding:0 !important;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-5 {
  padding: 5px !important;
}

.overflow-visible {
  overflow: visible !important;
}
.padding-tb-15 {
  padding: 15px 0;
}
.vertical-centered-container{
  display: table;
  width:100%;
  height:100%;
}
.vertical-centered-wrapper{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.v_align_m{
  vertical-align:middle !important;
}
.v_align_t{
  vertical-align:top !important;
}
.v_align_b{
  vertical-align: bottom !important;
}

.vertical-centered-inset{
  text-align: center;
  display: inline-block;
}
.line-through{
  text-decoration: line-through;
}
.text-no-transform {
  text-transform: none !important;
}
.lh_34{
  line-height:34px;
}
.fullwidth {
  width: 100%;
}
.maxfullwidth {
  max-width: 100%;
}
.cursor:hover {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.relative {
  position: relative;
}
.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.p_absolute {
  position: absolute !important;
}
.p_fixed {
  position: fixed !important;
}
.p_relative {
  position: relative !important;
}
.top_0 {
  top: 0 !important;
}
.bottom_0 {
  bottom: 0 !important;
}
.left_0 {
  left: 0 !important;
}
.right_0 {
  left: 0 !important;
}
.ws-normal {
  white-space: normal !important;
}
.no-background {
  background: none !important;
}
.opacity-10{
  opacity: 0.1;
}
.opacity-25{
  opacity: 0.25;
}
.opacity-50{
  opacity: 0.5;
}
.opacity-75{
  opacity: 0.75;
}
.opacity-100{
  opacity: 1;
}
.bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
}
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
}
.portlet {
  .portlet-title {
    .tools {
      .bootstrap-switch {
        margin-top: -6px;
      }
    }
  }
}