/***
jQuery UI Sliders(new in v1.1.1)
***/
.slider {
  border: 0;
  padding: 0;
  display: block;
  margin: 12px 5px;
  min-height: 11px; 
}

.ui-slider-vertical {
  width: 11px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -3px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -3px;
}

.ui-slider-vertical,
.ui-slider-handle {
  filter: none !important;
  background-image: none !important;
}