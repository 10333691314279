/* Cuborio Spinner */
html {
  background:#222;
  padding:0;
  margin:0;
  height:100%;
}
.load {
  position:absolute;
  top:calc(50% - 30px);
  left:calc(50% - 30px);
  width:70px;
  height:70px;
}
.block {
  position:relative;
  height:20px;
  width:20px;
  display:inline-block;
  background:#bf2740;
  transition:all 0.8s;
  animation: rot 5s linear infinite;
}
.block:nth-child(1) {
  animation-delay:3s;
}
.block:nth-child(2) {
  animation-delay:1.5s;
  animation: rot 15s linear infinite;
}
.block:nth-child(3) {
  animation-delay:2s;
}
.block:nth-child(4) {
  animation-delay:0.2s;
}
.block:nth-child(5) {
  animation-delay:4s;
}
.block:nth-child(6) {
  animation-delay:2s;
  animation: rot 7s linear infinite;
}
.block:nth-child(7) {
  animation-delay:0.4s;
}
.block:nth-child(8) {
  animation-delay:1.5s;
  animation: rot 6s linear infinite;
}
.block:nth-child(9) {
  animation-delay:25s;
  animation: rot 8s linear infinite;
}

@keyframes rot {
  0% {
    transform:none;
  }
  20% {
    transform:rotateZ(-90deg) rotateY(180deg);
  }
  40% {
    background:#951c3f;
    transform:none;
  }
  60% {
    background:white
  }
  80% {
    background:#ee2640;
  }
  90% {
    transform:none;
    background:#222;
  }
}