/**
    HEADER
 */
.page-header {

  &.navbar{
    .page-logo {
      .logo-default {
        height:60px;
        margin:8px 10px 0 0px;
      }
    }
    &.navbar-fixed-top{
      background:#FFF;
    }
  }

  .page-header-menu {

    display: inline;
    clear: none;
    float: left;
    background: none;
    position:relative;
    top:12px;

    .hor-menu.hor-menu-light {

      .navbar-nav {

        > li {

          &.current {

            border-bottom: 2px solid #900;
            padding-bottom: 11px;

          }
        }
      }
    }
  }
}

/**
    CONTAINER
 */
@media (min-width: $screen-md-min) { /* 992px */
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.page-container{
  background:#eff3f8;
  padding: 5px 20px 0 20px;
}

/**
    SPECIAL ROUTES
 */
body[data-route='ticket::orders.newest'] .page-container{

  /* IE10 Consumer Preview */
  background-image: -ms-linear-gradient(top, #EFF3F8 -30%, #EFACAC 100%);

  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top, #EFF3F8 -30%, #EFACAC 100%);

  /* Opera */
  background-image: -o-linear-gradient(top, #EFF3F8 -30%, #EFACAC 100%);

  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-0.5, #EFF3F8), color-stop(1, #EFACAC));

  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top, #EFF3F8 -30%, #EFACAC 100%);

  /* W3C Markup, IE10 Release Preview */
  background-image: linear-gradient(to bottom, #EFF3F8 -30%, #EFACAC 100%);
}

body[data-route='ticket::orders.progress'] .page-container{

  /* IE10 Consumer Preview */
  background-image: -ms-linear-gradient(top, #EFF3F8 -30%, #EFEA67 100%);

  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top, #EFF3F8 -30%, #EFEA67 100%);

  /* Opera */
  background-image: -o-linear-gradient(top, #EFF3F8 -30%, #EFEA67 100%);

  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-0.5, #EFF3F8), color-stop(1, #EFEA67));

  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top, #EFF3F8 -30%, #EFEA67 100%);

  /* W3C Markup, IE10 Release Preview */
  background-image: linear-gradient(to bottom, #EFF3F8 -30%, #EFEA67 100%);
}

body[data-route='ticket::orders.waiting'] .page-container{

  /* IE10 Consumer Preview */
  background-image: -ms-linear-gradient(top, #EFF3F8 -30%, #EF952D 100%);

  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top, #EFF3F8 -30%, #EF952D 100%);

  /* Opera */
  background-image: -o-linear-gradient(top, #EFF3F8 -30%, #EF952D 100%);

  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-0.5, #EFF3F8), color-stop(1, #EF952D));

  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top, #EFF3F8 -30%, #EF952D 100%);

  /* W3C Markup, IE10 Release Preview */
  background-image: linear-gradient(to bottom, #EFF3F8 -30%, #EF952D 100%);
}

body[data-route='ticket::orders.confirmed'] .page-container{

  /* IE10 Consumer Preview */
  background-image: -ms-linear-gradient(top, #EFF3F8 -30%, #70EF8E 100%);

  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top, #EFF3F8 -30%, #70EF8E 100%);

  /* Opera */
  background-image: -o-linear-gradient(top, #EFF3F8 -30%, #70EF8E 100%);

  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-0.5, #EFF3F8), color-stop(1, #70EF8E));

  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top, #EFF3F8 -30%, #70EF8E 100%);

  /* W3C Markup, IE10 Release Preview */
  background-image: linear-gradient(to bottom, #EFF3F8 -30%, #70EF8E 100%);
}


/**
    FOOTER
 */
.page-footer {

  padding: 0;

  .page-footer-inner {
    margin-bottom: 0;

  }
}
p.copyright{
  margin-top:0px;
}

/**
    LOGIN
 */

body.login {

  height: auto;
  background: none !important;
  .content {
    background: rgba(0, 0, 0, 0.5);
    .form-actions{
      .rememberme {
        margin-top: 8px;
        display: inline-block;
      }
      .btn {
        margin-top: 1px;
      }
    }
    .forget-password {
      margin-top: 25px;
    }
  }
}
@media (max-width: 1023px) {

  .user-login-5 {

    .mt-login-5-bsfix {
      width: auto;
    }
  }
}