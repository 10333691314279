@import '../global/_variables'; // global variables.
@import '../global/components/_mixins'; // global mixings.

/***
Anout Us Page
***/


/* BEGIN HEADER */
.about-header {
  height: 500px;
  background-image: url("#{$page-media-path}bg/5.jpg");
  background-position: center;
  text-align: center;
  margin: 0 -20px;
}

.about-header h1 { 
  color: #fff;
  font-size: 55px;
  font-weight: 600;
  margin-top: 130px;
  text-shadow: 1px 1px 0px rgba(0,0,0, 0.2);
 }

.about-header h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 40px;
 }
/* END HEADER */

/* BEGIN CARD */
.card-icon {
  width: 100%;
  text-align: center;
  overflow: hidden;

}

.card-icon i {
  font-size: 50px;
  border: 1px solid #ecf0f4;
  -webkit-border-radius: 50%; 
  -moz-border-radius: 50%; 
  border-radius: 50%; 
  padding: 47px 30px;
  margin: 30px 0 30px 0;
}

.card-title {
  text-align: center;
}

.card-title span {
  font-size: 18px;
  font-weight: 600;
  color: #373d43;
}

.card-desc {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.card-desc span {
  font-size: 14px;
  font-weight: 400;
  color: #808a94;
}
/* END CARD */

/* BEGIN LINKS BLOCK */
.about-links-cont {
  background-color: #fff;
  margin:0 -20px;
}

.about-links-cont .about-links {
  padding: 70px 0 70px 70px;
}

.about-links-cont .about-image {
  padding-left: 110px;
}

.about-links-item h4 {
  font-size: 18px;
  font-weight: 600;
  color: #373d43;

}

.about-links-item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.about-links-item ul li {
  padding-top: 5px;
}

.about-links-item ul li a {
  font-size: 14px;
  font-weight: 400;
  color: #4a8fba;
}

.about-image {
  background-repeat: no-repeat;
  background-size: cover;
}
/* END LINKS BLOCK */

/* BEGIN MEMBERS SUCCESS STORIES */
.stories-header {
  text-align: center;
}

.stories-header h1 {
  color: #373d43;
  font-size: 35px;
  font-weight: 600;
 }

.stories-header h2 {
  color: #808a94;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 20px;
 }

.stories-cont .photo {
  width: 100%;
}

.stories-cont .photo img {
  margin: 30px auto;
  width: 130px;
  height: 130px;
  -webkit-border-radius: 50% !important; 
  -moz-border-radius: 50% !important; 
  border-radius: 50% !important; 
}

.stories-cont .title {
  text-align: center;
}

.stories-cont .title span {
  font-size: 18px;
  font-weight: 600;
  color: #373d43;
}

.stories-cont .desc {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.stories-cont .desc span {
  font-size: 14px;
  font-weight: 400;
  color: #808a94;
}

.stories-footer {
  text-align: center;
}
/* END MEMBERS SUCCESS STORIES */

/* BEGIN ABOUt AND VIDEO */

.about-text {
  height:500px;
  padding:0 !important;

  > h4 {
    background-color: #d9534f;
      font-size: 24px; 
      font-weight: 600;
      color: #fff;
      padding:1em 20px;
      margin:0;

    > i{
        font-size:24px !important;
        color:#fff;
      }
    }

    >p{
      color: #808a94;
    }

    ul, 
    > p, 
    .about-quote {
      padding-left:20px;
      padding-right:20px;
    }

    li{
      margin-bottom:0.5em;
    }

    .about-quote{

      > h3{
        border-left: 3px solid;
        border-color:#ccc;
        padding-left:1em;
        font-style: italic;
        line-height: 1.3em;
      }

      > .about-author{
        text-align:right;
      }
    }
}

.about-image{
  height:545px;
}

.about-links-item{
  margin-bottom:2em;
}


/* END ABOUt AND VIDEO */

/* BEGIN RESPONSIVE */

@media (max-width:600px) {
  .about-text{
    height:auto;
    padding-bottom:1.5em !important;
  }

  .about-image{
    margin:0 -20px;
  }
}

/* END RESPONSIVE */





