/***
Jstree
***/

.jstree-default .jstree-clicked {
  border: 0;
  background-color: #e1e1e1;
  box-shadow:none;
}

.jstree-default .jstree-hovered {
  border: 0;
  background-color: #eee;
  box-shadow:none;
}

.jstree-default .jstree-wholerow-clicked,
.jstree-wholerow .jstree-wholerow-clicked {
  background: none;
  border: 0;
  background-color: #e1e1e1;
  box-shadow:none;
}

.jstree-default .jstree-wholerow-hovered,
.jstree-wholerow .jstree-wholerow-hovered {
  border: 0;
  background-color: #eee;
  box-shadow:none;
} 

.jstree-icon.icon-lg {
  margin-top: 1px;
}

.jstree-open > .jstree-anchor > .fa-folder:before {
  margin-left: 2px;
  content: "\f07c"; 
}

.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
  background-repeat: no-repeat;
}

.vakata-context,
.vakata-context ul {
  padding: 0;
  min-width: 125px;
  background-color: #ffffff; 
  font-size: 14px;
  font-family: "Segoe UI",Helvetica, Arial, sans-serif;
  box-shadow: 5px 5px rgba(#666, 0.1);
  border: 1px solid #efefef;
}

.vakata-context li {
    border: 0;
    a { 
      padding: 0 10px;       
      border: 0;

      i {
        display: none;   
      }

      .vakata-contextmenu-sep {  
        display: none;  
      }

    }
}

.vakata-context .vakata-context-hover > a,
.vakata-context li a:hover {
  background-color: #eee;   
  color: #333;  
  box-shadow: none;      
  margin: 0;   

  .span,
  .ins {
    border: 0 !important;
  }
}

.vakata-context li a span,
.vakata-context li a ins {
  display: none;
  border: 0 !important;
}

.vakata-context .vakata-context-separator a,   
.vakata-context-rtl .vakata-context-separator a {
  margin: 0;
  border: 0;

}

.jstree-rename-input {
  background-color: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  outline: none !important;
  padding: 2px 6px !important;
  margin-right: -4px !important;
}