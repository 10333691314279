/***
Forms
***/

.form-control {
    outline: none !important;
    box-shadow: none !important; 

    &.height-auto {
        height: auto;
    }
}

.input-group-addon {
    > i {
        color: lighten($input-border, 1%);
    }
}

/***
Custom color buttons 
***/

@each $name, $colors in $component-colors {
    .form-control.#{$name} {
        border-color: map-get($colors, base);

        &:focus {
            border-color: darken(map-get($colors, base), 10%);
        }
    }

    .form-control.focus-#{$name} {
        &:focus {
            border-color: map-get($colors, base);
        }   
    }
}

.input-xxs {
    width: 45px !important;
}

/* Form uneditable input */

.uneditable-input {
    padding: 6px 12px;
    min-width: 206px;
    font-size: 14px;
    font-weight: normal;
    height: 34px;
    color: $input-color;
    background-color: $input-bg;
    border: 1px solid $input-border;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control-static {
    display: inline-block;
    margin: 0;
}

/* Form input sizing */

.input-mini {
    width: 45px !important;
}

.input-xsmall {
    width: 80px !important;
}

.input-small {
    width: 145px !important;
}

.input-medium {
    width: 240px !important;
}

.input-large {
    width: 320px !important;
}

.input-xlarge {
    width: 420px !important;
}

@media (max-width: $screen-sm-min) { /* 768px */  
    .input-large {
        width: 250px !important;
    }

    .input-xlarge {
        width: 300px !important;   
    }
}

.input-inline {
    display: inline-block;
    width: auto;  
    vertical-align: middle;
}

.input-fixed {
   overflow: hidden; 
}

.input-fixed {
   overflow: hidden; 
}

.form-group {
    .input-inline  {
        margin-right: 5px;
    }
}

@media (max-width: $screen-sm-min) { /* 768px */  

    .input-lg {
        width: 250px !important;
    }

    .input-xlg {
        width: 300px !important;   
    }

}

/* Circle Inputs */

.input-circle {
  border-radius: 25px !important;  
}

.input-circle-right {
  border-radius:  0 25px 25px 0 !important;
}

.input-circle-left { 
  border-radius: 25px 0 0 25px !important;
}

.input-circle-bottom {
  border-radius:  0 0 25px 25px !important;
}

.input-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

@if $theme-type == "material-design" {  
    .input-group-btn {
        .btn {
            box-shadow: none;
        }
    }
}  

$color-tick: #888888;
$color-default: #E6E6E6;
$color-hover: darken($color-default, 5%);   
$color-checked: $color-hover;  

// Basic control styles
.mt-radio,
.mt-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    @include transition('all 0.3s');

    &.mt-radio-disabled,
    &.mt-checkbox-disabled {
        @include opacity(0.8);
        cursor: not-allowed;
    }

    // Hide default browser input
    > input {
        position: absolute;
        z-index: -1;
        @include opacity(0);
    }

    > span {
        border: 1px solid transparent;
        position: absolute;
        top: 1px;
        left: 0;
        height: 18px;
        width: 18px;
        background: $color-default;

        &:after {
            content:'';
            position: absolute;
            display: none; // Hide check
        }
    }

    &:hover > input:not([disabled]) ~ span,
    > input:focus ~ span {
        background: $color-hover;
        @include transition('all 0.3s');
    }

    > input:checked ~ span {
        @include transition('all 0.3s');
        background: $color-checked;

        &:after {
            display: block;
        }
    }

    &:hover > input:not([disabled]):checked ~ span,
    > input:checked ~ span {
        @include transition('all 0.3s');
        background: $color-hover;
    }

    > input:disabled ~ span {
        @include opacity(0.6);
        pointer-events: none;
    }

    &.mt-radio-outline,
    &.mt-checkbox-outline {
        > span {
            border: 1px solid darken($color-default, 5%);
            background: none;
        }

        &:hover > input:not([disabled]) ~ span,
        > input:focus ~ span {
            border: 1px solid darken($color-default, 5%);
            background: none;
        }

        > input:checked ~ span {
            border: 1px solid darken($color-default, 5%);
            background: none;
        }

        &:hover > input:not([disabled]):checked ~ span,
        > input:checked ~ span {
            border: 1px solid darken($color-default, 5%);
            background: none;
        }
    }
}

.mt-radio {
    > span {
        @include border-radius(50%, '!important'); // Makes radio buttons circlular

        &:after {
            left: 5px;
            top: 5px;
            height: 6px;  
            width: 6px;
            border-radius: 100% !important;
            background: $color-tick;
        }
    }

    > input:disabled ~ span {
        &:after {
            background: $color-tick;
        }
    }

    &.mt-radio-single {
        th > &, 
        td > & {
            right: -5px;
        }
    }
}

.mt-checkbox {
    > span {
        &:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid $color-tick;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    > input:disabled ~ span {
        &:after {
            border-color: $color-tick;
        }
    }

    .form-inline & {
        margin-left: 15px;
        margin-right: 15px;
    }

    &.mt-checkbox-single {

        th > &, 
        td > & {
            right: -5px;
        }
    }
}

.mt-checkbox-list,
.mt-radio-list {
    padding: 10px 0;

    .form-horizontal .form-group & {
        padding-top: 0;
    }

    .mt-checkbox,
    .mt-radio {
        display: block;  
    }   
}

.mt-checkbox-inline,
.mt-radio-inline {
    padding: 10px 0;

    .form-horizontal .form-group & {
        padding-top: 8px;
    }

    .mt-checkbox,
    .mt-radio {
        display: inline-block;
        margin-right: 15px;

        &:last-child {
            margin-right: 0; 
        }
    }
}