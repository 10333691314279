/***
Page content
***/

/* Page header */

.page-head {
	
	@include clearfix();

	.page-title {
		display: inline-block;
		float: left;
		padding: 5px 0 10px 0;

		> h1 {
			color: #697882;
			font-size: 22px;
			font-weight: 400;
			margin: 0;
			padding: 0;

			> small {
				color: #9eacb4;
				font-size: 13px;
				font-weight: 400; 
			}
		}
	}

	$page-breadcrumb-toolbar-main-color: lighten(#EFF2F4, 3%);

	.page-toolbar {
		display: inline-block;
		float: right;
	}
}

.breadcrumb {
	background: none;
	padding: 0 0 15px 0;
	margin: 0;
	color: #c5ccd5;
}

.breadcrumb > li + li:before {
  display: none;
}
.breadcrumb .fa {
  font-size: 6px;
  margin: 0 2px 0 4px;
  position: relative;
  top: -1px;
}
.breadcrumb > .active {
  color: #9eacb4;
}

/* Page content */

.page-content {  
	margin-top: 0px;   
	padding: 0px;
	
	.page-full-width & {
		margin-left: 0px !important;
	}
}

@media (min-width: $screen-md-min) { /* 992px */

	/* Page content */
	.page-content-wrapper {
		float: left;
		width: 100%;

		.page-content { 
			margin-left: $sidebar-width; 
			margin-top: 0px;
			min-height: $page-content-min-height; 
			padding: 10px 0 0 20px;

			&.no-min-height {
				min-height: auto;
			}			

			.page-sidebar-fixed.page-sidebar-hover-on & {
				margin-left: $sidebar-collapsed-width; 
			}

			.page-sidebar-reversed & {
				margin-left: 0 !important;
				margin-right: $sidebar-width !important;
				padding-left: 0;
				padding-right: 20px;
			}

			.page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on & {
				margin-left: 0;
				margin-right: $sidebar-collapsed-width;
			}

			.page-sidebar-reversed.page-sidebar-closed  & {	
				margin-left: 0 !important;
				margin-right: $sidebar-collapsed-width !important;
			}

			.page-sidebar-closed  & {
				margin-left: $sidebar-collapsed-width !important;
			}

			.page-sidebar-closed.page-sidebar-hide & {
				margin-left: 0 !important;
			}

			.page-sidebar-closed.page-sidebar-reversed.page-sidebar-hide & {
				margin-right: 0 !important;
			}

			.page-full-width & {
				margin-left: 0px !important;
			}
		}		
	}
}

@media (max-width: $screen-sm-max) { /* 991px */

	/* Boxed page container  */
	.page-boxed > .container { 
		width: 100%;
 		max-width: none !important;
 		margin: 0 !important;
 		padding: 0 !important; 
 	}

 	/* Page content */
	.page-content-wrapper {
		.page-content {
			margin: 0px !important;
			padding: 10px !important;  		
			min-height: 280px; 
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { /*  768px & 991px */

	/* Boxed page container */
	.page-boxed > .container {
 		margin: auto !important;
 	}
}

@media (max-width: $screen-xs-max) { /* 767px */

	/* Page content */
	.page-content-wrapper {
		.page-content {
			padding: 20px 10px 10px 10px !important;
			overflow: hidden;

			/* Page title */
			.page-title {
				margin-bottom: 15px;
				font-size: 20px;

				small {
					font-size: 13px;
					padding-top: 3px;
				}
			}
		}
	}
}

@media (max-width: $screen-xs-min) { /* 480px */

	/* Dashboard date range panel */
	.page-content-wrapper {
		.page-content {		
			.page-title {
				small {
					display: block;
					clear: both;
				}
			}
		}
	}
}