.minicolors {
  position: relative;
}

.minicolors-sprite {
  background-image: url(../img/jquery.minicolors.png);
}

.minicolors-swatch {
  position: absolute;
  vertical-align: middle;
  background-position: -80px 0;
  border: solid 1px #ccc;
  cursor: text;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.minicolors-swatch-color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.minicolors input[type=hidden] + .minicolors-swatch {
  width: 28px;
  position: static;
  cursor: pointer;
}

.minicolors input[type=hidden][disabled] + .minicolors-swatch {
  cursor: default;
}

/* Panel */
.minicolors-panel {
  position: absolute;
  width: 173px;
  background: white;
  border: solid 1px #CCC;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  z-index: 99999;
  box-sizing: content-box;
  display: none;
}

.minicolors-panel.minicolors-visible {
  display: block;
}

/* Panel positioning */
.minicolors-position-top .minicolors-panel {
  top: -154px;
}

.minicolors-position-right .minicolors-panel {
  right: 0;
}

.minicolors-position-bottom .minicolors-panel {
  top: auto;
}

.minicolors-position-left .minicolors-panel {
  left: 0;
}

.minicolors-with-opacity .minicolors-panel {
  width: 194px;
}

.minicolors .minicolors-grid {
  position: relative;
  top: 1px;
  left: 1px; /* LTR */
  width: 150px;
  height: 150px;
  margin-bottom: 2px;
  background-position: -120px 0;
  cursor: crosshair;
}
[dir=rtl] .minicolors .minicolors-grid {
  right: 1px;
}

.minicolors .minicolors-grid-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
}

.minicolors-slider-saturation .minicolors-grid {
  background-position: -420px 0;
}

.minicolors-slider-saturation .minicolors-grid-inner {
  background-position: -270px 0;
  background-image: inherit;
}

.minicolors-slider-brightness .minicolors-grid {
  background-position: -570px 0;
}

.minicolors-slider-brightness .minicolors-grid-inner {
  background-color: black;
}

.minicolors-slider-wheel .minicolors-grid {
  background-position: -720px 0;
}

.minicolors-slider,
.minicolors-opacity-slider {
  position: absolute;
  top: 1px;
  left: 152px; /* LTR */
  width: 20px;
  height: 150px;
  background-color: white;
  background-position: 0 0;
  cursor: row-resize;
}
[dir=rtl] .minicolors-slider,
[dir=rtl] .minicolors-opacity-slider {
  right: 152px;
}

.minicolors-slider-saturation .minicolors-slider {
  background-position: -60px 0;
}

.minicolors-slider-brightness .minicolors-slider {
  background-position: -20px 0;
}

.minicolors-slider-wheel .minicolors-slider {
  background-position: -20px 0;
}

.minicolors-opacity-slider {
  left: 173px; /* LTR */
  background-position: -40px 0;
  display: none;
}
[dir=rtl] .minicolors-opacity-slider {
  right: 173px;
}

.minicolors-with-opacity .minicolors-opacity-slider {
  display: block;
}

/* Pickers */
.minicolors-grid .minicolors-picker {
  position: absolute;
  top: 70px;
  left: 70px;
  width: 12px;
  height: 12px;
  border: solid 1px black;
  border-radius: 10px;
  margin-top: -6px;
  margin-left: -6px;
  background: none;
}

.minicolors-grid .minicolors-picker > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  border: solid 2px white;
  box-sizing: content-box;
}

.minicolors-picker {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 2px;
  background: white;
  border: solid 1px black;
  margin-top: -2px;
  box-sizing: content-box;
}

/* Swatches */
.minicolors-swatches,
.minicolors-swatches li {
  margin: 5px 0 3px 5px; /* LTR */
  padding: 0;
  list-style: none;
  overflow: hidden;
}
[dir=rtl] .minicolors-swatches,
[dir=rtl] .minicolors-swatches li {
  margin: 5px 5px 3px 0;
}

.minicolors-swatches .minicolors-swatch {
  position: relative;
  float: left; /* LTR */
  cursor: pointer;
  margin:0 4px 0 0; /* LTR */
}
[dir=rtl] .minicolors-swatches .minicolors-swatch {
  float: right;
  margin:0 0 0 4px;
}

.minicolors-with-opacity .minicolors-swatches .minicolors-swatch {
  margin-right: 7px; /* LTR */
}
[dir=rtl] .minicolors-with-opacity .minicolors-swatches .minicolors-swatch {
  margin-right: 0;
  margin-left: 7px;
}

.minicolors-swatch.selected {
  border-color: #000;
}

/* Inline controls */
.minicolors-inline {
  display: inline-block;
}

.minicolors-inline .minicolors-input {
  display: none !important;
}

.minicolors-inline .minicolors-panel {
  position: relative;
  top: auto;
  left: auto; /* LTR */
  box-shadow: none;
  z-index: auto;
  display: inline-block;
}
[dir=rtl] .minicolors-inline .minicolors-panel {
  right: auto;
}

/* Default theme */
.minicolors-theme-default .minicolors-swatch {
  top: 5px;
  left: 5px; /* LTR */
  width: 18px;
  height: 18px;
}
[dir=rtl] .minicolors-theme-default .minicolors-swatch {
  right: 5px;
}
.minicolors-theme-default .minicolors-swatches .minicolors-swatch {
  margin-bottom: 2px;
  top: 0;
  left: 0; /* LTR */
  width: 18px;
  height: 18px;
}
[dir=rtl] .minicolors-theme-default .minicolors-swatches .minicolors-swatch {
  right: 0;
}
.minicolors-theme-default.minicolors-position-right .minicolors-swatch {
  left: auto; /* LTR */
  right: 5px; /* LTR */
}
[dir=rtl] .minicolors-theme-default.minicolors-position-left .minicolors-swatch {
  right: auto;
  left: 5px;
}
.minicolors-theme-default.minicolors {
  width: auto;
  display: inline-block;
}
.minicolors-theme-default .minicolors-input {
  height: 20px;
  width: auto;
  display: inline-block;
  padding-left: 26px; /* LTR */
}
[dir=rtl] .minicolors-theme-default .minicolors-input {
  text-align: right;
  unicode-bidi: plaintext;
  padding-left: 1px;
  padding-right: 26px;
}
.minicolors-theme-default.minicolors-position-right .minicolors-input {
  padding-right: 26px; /* LTR */
  padding-left: inherit; /* LTR */
}
[dir=rtl] .minicolors-theme-default.minicolors-position-left .minicolors-input {
  padding-right: inherit;
  padding-left: 26px;
}

/* Bootstrap theme */
.minicolors-theme-bootstrap .minicolors-swatch {
  z-index: 2;
  top: 3px;
  left: 3px; /* LTR */
  width: 28px;
  height: 28px;
  border-radius: 3px;
}
[dir=rtl] .minicolors-theme-bootstrap .minicolors-swatch {
  right: 3px;
}
.minicolors-theme-bootstrap .minicolors-swatches .minicolors-swatch {
  margin-bottom: 2px;
  top: 0;
  left: 0; /* LTR */
  width: 20px;
  height: 20px;
}
[dir=rtl] .minicolors-theme-bootstrap .minicolors-swatches .minicolors-swatch {
  right: 0;
}
.minicolors-theme-bootstrap .minicolors-swatch-color {
  border-radius: inherit;
}
.minicolors-theme-bootstrap.minicolors-position-right > .minicolors-swatch {
  left: auto; /* LTR */
  right: 3px; /* LTR */
}
[dir=rtl] .minicolors-theme-bootstrap.minicolors-position-left > .minicolors-swatch {
  right: auto;
  left: 3px;
}
.minicolors-theme-bootstrap .minicolors-input {
  float: none;
  padding-left: 44px; /* LTR */
}
[dir=rtl] .minicolors-theme-bootstrap .minicolors-input {
  text-align: right;
  unicode-bidi: plaintext;
  padding-left: 12px;
  padding-right: 44px;
}
.minicolors-theme-bootstrap.minicolors-position-right .minicolors-input {
  padding-right: 44px; /* LTR */
  padding-left: 12px; /* LTR */
}
[dir=rtl] .minicolors-theme-bootstrap.minicolors-position-left .minicolors-input {
  padding-right: 12px;
  padding-left: 44px;
}
.minicolors-theme-bootstrap .minicolors-input.input-lg + .minicolors-swatch {
  top: 4px;
  left: 4px; /* LTR */
  width: 37px;
  height: 37px;
  border-radius: 5px;
}
[dir=rtl] .minicolors-theme-bootstrap .minicolors-input.input-lg + .minicolors-swatch {
  right: 4px;
}
.minicolors-theme-bootstrap .minicolors-input.input-sm + .minicolors-swatch {
  width: 24px;
  height: 24px;
}
.minicolors-theme-bootstrap .minicolors-input.input-xs + .minicolors-swatch {
  width: 18px;
  height: 18px;
}
.input-group .minicolors-theme-bootstrap:not(:first-child) .minicolors-input {
  border-top-left-radius: 0; /* LTR */
  border-bottom-left-radius: 0; /* LTR */
}
[dir=rtl] .input-group .minicolors-theme-bootstrap .minicolors-input {
  border-radius: 4px;
}
[dir=rtl] .input-group .minicolors-theme-bootstrap:not(:first-child) .minicolors-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=rtl] .input-group .minicolors-theme-bootstrap:not(:last-child) .minicolors-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/* bootstrap input-group rtl override */
[dir=rtl] .input-group .form-control,
[dir=rtl] .input-group-addon,
[dir=rtl] .input-group-btn > .btn,
[dir=rtl] .input-group-btn > .btn-group > .btn,
[dir=rtl] .input-group-btn > .dropdown-toggle {
  border: 1px solid #ccc;
  border-radius: 4px;
}
[dir=rtl] .input-group .form-control:first-child,
[dir=rtl] .input-group-addon:first-child,
[dir=rtl] .input-group-btn:first-child > .btn,
[dir=rtl] .input-group-btn:first-child > .btn-group > .btn,
[dir=rtl] .input-group-btn:first-child > .dropdown-toggle,
[dir=rtl] .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
[dir=rtl] .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
[dir=rtl] .input-group .form-control:last-child,
[dir=rtl] .input-group-addon:last-child,
[dir=rtl] .input-group-btn:last-child > .btn,
[dir=rtl] .input-group-btn:last-child > .btn-group > .btn,
[dir=rtl] .input-group-btn:last-child > .dropdown-toggle,
[dir=rtl] .input-group-btn:first-child > .btn:not(:first-child),
[dir=rtl] .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Semantic Ui theme */
.minicolors-theme-semanticui .minicolors-swatch {
  top: 0;
  left: 0; /* LTR */
  padding: 18px;
}
[dir=rtl] .minicolors-theme-semanticui .minicolors-swatch {
  right: 0;
}
.minicolors-theme-semanticui input {
  text-indent: 30px;
}
